/**
 * @全局公共事件
 */

import request from "@/api/axios.js";
import storage from "../js/comm/storage";
import router from "@/router/index.js";
import store from '@/store/index';
import { ElLoading } from 'element-plus';
import routerVerify from '../js/comm/routerVerify';
import axios from "axios";

export default {
    data() {
        return {
            elLoading: null, // 全局加载
            elRefLoading: null, // 元素加载
            color: 'var(--theme_color)', // 全局颜色
            maxNode: '#globalApp', // 最大节点
            globalFloat: 40, // 全局浮动固定高度
            throttleTimer: null, // 节流
            debounceTimer: null, // 防抖
        }
    },

    // 验证手机号
    phone(phones) {
        var myreg = /^[1][0-9][0-9]{9}$/;
        return !myreg.test(phones) ? false : true;
    },

    // 路由跳转
    jump(url, data) {
        if (url == '/' || !url) return router.push('/');

        router.push({
            path: url,
            query: data
        })
    },

    // 外链跳转
    jumpExternal(url) {
        if (!url) return;

        let newUrl = url.split('#');
        newUrl = newUrl.length > 1 ? newUrl[1] : newUrl[0];
        let val = newUrl.split('?');

        if (routerVerify.verify(val[0])) return;
        if (routerVerify.verifyLogin(val[0])) return;
        window.open(url.indexOf('#') == -1 ? `#${url}` : url, '_blank')
    },

    // 专业跳转 (treeId专业类 id专业 url跳转路径)
    majorJump(treeId, id, url) {
        // 暂无详情
        if (!id && !treeId) return;

        // 初始化
        let jumpData = {
            url: '/dataQuery/major/detail_first',
            id: '',
            treeId: ''
        }

        if (id) {
            // 专业
            jumpData.url = '/dataQuery/major/detail_second'
            jumpData.id = id;
            jumpData.treeId = treeId;
        } else {
            // 专业类
            jumpData.id = treeId;
            jumpData.treeId = treeId;
        }

        // 判断赋值
        if (url) jumpData.url = url;

        // 新窗口跳转
        window.open(`#${jumpData.url}?id=${jumpData.id}&treeId=${jumpData.treeId}`, '_blank')

        jumpData = null;
    },

    // 返回顶部
    backTop(value = 10) {
        setTimeout(() => {
            let box = document.querySelector('#globalApp')
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                box.scrollTop;
            if (scrollTop > 0) {
                scrollTop -= value;
                box.scrollTop = document.documentElement.scrollTop = scrollTop;
                this.backTop(value * value);
            }
        }, 10);
    },

    // 滚动到元素开始位置
    scrollIntoView(elRef = '') {
        if (elRef) {
            const element = elRef && document?.querySelector(elRef) || window;
            element?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
    },

    // 判断是否登录
    loginStatus(open = true) {
        // 未登录返回true
        if (!localStorage.getItem('token')) {
            // open判断是否开启登录弹窗
            if (open) store.dispatch('addloginStatus', { status: true, index: 0 });
            return true;
        }
        return false;
    },

    // 数字千分化
    numThousand(num) {
        let value = Number(num);
        if (!value) return 0
        // 获取整数部分
        const intPart = Math.trunc(value)
        // 整数部分处理，增加,
        const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
        // 预定义小数部分
        let floatPart = ''
        // 将数值截取为小数部分和整数部分
        const valueArray = value.toString().split('.')
        if (valueArray.length === 2) { // 有小数部分
            floatPart = valueArray[1].toString() // 取得小数部分
            return intPartFormat + '.' + floatPart
        }
        return intPartFormat + floatPart
    },

    // 开启加载
    openLoading(elRef = 'document.body') {
        if (elRef != 'document.body') {
            this.elRefLoading = ElLoading.service({
                target: elRef,
                lock: true,
                text: 'Loading',
                background: 'rgba(255, 255, 255, 0.7)',
            })
        } else {
            this.elLoading = ElLoading.service({
                target: elRef,
                lock: true,
                text: 'Loading',
                background: 'rgba(255, 255, 255, 0.7)',
            })
        }

    },

    // 关闭加载
    closeLoading() {
        if (this.elLoading) this.elLoading.close();
        if (this.elRefLoading) this.elRefLoading.close();
    },

    // 判断渲染字段
    verificationFields(data, key, init) {
        let name = key ? data[key] : data;
        if (!name) name = init ? init : '';

        return JSON.parse(JSON.stringify(name));
    },

    // 下载文件
    downloadFile(fileUrl, fileName) {
        if (!fileUrl) return false;

        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);

        window.open(fileUrl, '_blank');
        return true;
    },

    // 导出文件
    async exportExecl(fileUrl, fileName) {
        if (!fileUrl) return false;
        this.openLoading();

        try {
            const res = await axios.get(
                process.env.VUE_APP_API_URL + fileUrl,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        'token': storage.get('token')
                    },
                    responseType: "blob",
                    timeout: 50000,
                    withCredentials: true,
                })

            let dataBlob = new Blob([res.data], {
                type: 'application/octet-stream;charset=utf-8'
            })

            let url = URL.createObjectURL(dataBlob);
            let a = document.createElement('a');
            a.href = url;
            a.download = `${fileName}.xlsx`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            // 释放 URL 对象
            window.URL.revokeObjectURL(url);
        } catch (error) {

        }

        this.closeLoading()
    },

    // 节流
    throttle(fn, delay = 300) {
        if (this.throttleTimer == null) {
            this.throttleTimer = setTimeout(() => {
                fn()

                clearTimeout(this.throttleTimer)
                this.throttleTimer = null
            }, delay);
        }
    },

    // 防抖
    debounce(fn, delay = 300) {
        return function (...args) {
            clearTimeout(this.debounceTimer)
            this.debounceTimer = setTimeout(() => {
                fn.call(this, ...args)
            }, delay);
        }
    },

    // 富文本图片优化
    rickTextInit(content) {
        const regex = new RegExp('<img', 'gi');
        return !content ? content : content.replace(regex, `<img style="max-width: 100%;"`)
    },

    // 提交用户默认地区
    setUserProvince(areaId) {
        if (!areaId) return;

        request.post('/member/modify_information', { area_id: areaId }).then((res) => {
            if (res.data.code == 200) {
                storage.set('userInfo', JSON.stringify(res.data.data));
                storage.set('province', JSON.stringify(res.data.data.area));

                store.dispatch('addUserInfo', {
                    type: 'info',
                    data: res.data.data ? res.data.data : {}
                })

                store.dispatch('addProvince', res.data.data.area);
            }
        })
    },

    /**
     * @dataStorageHanle 缓存数据操作
     * @param {String} type  类型 get:获取 set:存储 query:查询
     * @param {String} key   字段
     */
    dataStorageHanle(type = 'get', key, value) {
        if (!key) return new Error('请传入查询标识');

        const list = storage.get(key);

        // 获取
        if (type == 'get') return typeof list === 'string' && list ? JSON.parse(list) : {};
        // 查询
        if (type == 'query') return typeof list === 'string' && list ? true : false;
    },


    // 获取选科数据
    getSelectSubject() {
        return new Promise((resolve, reject) => {
            if (!this.dataStorageHanle('query', 'province')) return reject({ msg: '省份id错误' });

            request.get('member/selected_subject_and_fraction', {
                province_id: this.dataStorageHanle('get', 'province')?.province_id
            }).then((res) => {
                if (res.data.data) {
                    res.data.data.selected_subject_and_fraction && store.dispatch('addUserSubject', res.data.data.selected_subject_and_fraction);
                    resolve(res.data.data);
                } else {
                    reject({
                        ...res.data.data
                    })
                }
            }).catch(err => {
                new Error('获取选科失败', err);

                reject({
                    ...err
                })
            })
        })
    },

    // 获取用户数据
    getUserInfo(set_province = true) {
        return new Promise((resolve, reject) => {
            request.get('/member/information').then((res) => {
                if (res.data.code == 200) {
                    this.getSelectSubject()

                    // 存储个人信息
                    store.dispatch('addUserInfo', {
                        type: 'info',
                        data: res.data.data ? res.data.data : {}
                    })
                    storage.set('userInfo', JSON.stringify(res.data.data));

                    if (set_province) {
                        if (res.data.data.area) {
                            store.dispatch('addProvince', res.data.data.area);
                            // 缓存
                            storage.set('province', JSON.stringify(res.data.data.area));
                        } else {
                            storage.del('province');
                            store.commit('openProvincePopup', true);
                        }
                    }

                    // 权限存储
                    routerVerify.set(res.data.data.route_pc)

                    resolve(res)
                } else {
                    reject(res)
                }
            }).catch(err => {
                if (!this.dataStorageHanle('query', 'province')) {
                    store.commit('openProvincePopup', true);
                }
                reject(err)
            })
        })
    },

    /**
     * @browserIsHide 判断浏览器的隐身(无痕)模式
     */
    browserIsHide() {
        let fs = window.RequestFileSystem || window.webkitRequestFileSystem;
        if (!fs) {
            console.log("check failed?");
        } else {
            fs(window.TEMPORARY, 100, function () {
                console.log('非无痕模式')
            }, function () {
                alert('您已开启无痕模式，为了不影响正常功能使用，请立即关闭！');
            });
        }
    }
}
