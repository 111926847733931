<template>
	<div id="globalApp">
		<router-view />
	</div>
</template>

<style lang="scss">
* {
	font-family: 微软雅黑;
}

/* 全局颜色 */
@import '@/global/scss/global-color.scss';
@import '@/global/scss/element.scss';
/* 公共样式 */
@import '@/assets/css/comm.css';
@import '@/assets/css/layout.css';
@import '@/assets/css/scrollbar.css';

/* 图标 */
@import '@/assets/iconfont/iconfont.css';

#globalApp {
	min-width: 1240px;
	overflow-y: auto;
	overflow-x: hidden;
	height: 100vh;
}

.pageWrap {
	width: 1240px;
	margin: 0 auto;
	padding-left: 20px;
	padding-right: 20px;
	box-sizing: border-box;
}

.pageBox {
	width: 100%;
	background: #fff;
	min-height: calc(100vh - 144px);
	// min-height: 100vh;
	margin: 0 auto;
	box-sizing: border-box;
	font-size: 14px;
	font-weight: 500;
	color: #333;
}

.pagePd {
	padding: 15px 0 50px 0;
}

.pageTop {
	padding-top: 94px !important;
}

.headerHeight {
	height: 94px;
}
</style>
